@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

html {
  height: 100%;
}

* {
  margin: 0;
  font-family: "Nunito Sans";
  color: #F4F7F5;
}

body {
  background-color: #08090A;
  overflow-x: hidden;
  height: 100%;
}

.link {
  color: #456e5f !important;
}

.typewriter {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.65vw;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  padding-right: 10px;
  border-right: 0.15em solid rgb(184, 181, 181);
  border-radius:1.2px;
  margin-top: 4px;
  white-space: nowrap; /* Keeps the content on a single line */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  width: fit-content;
  animation:
    0.9s steps(14) typing2 3.2s,
    1.2s steps(9) erase 2s,
    0.8s steps(8) typing,
    blinkcursor 1.1s step-end infinite;

}

.typewriter::before {
  content: "$ whoami";
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3.2s;
  animation-name: changetext;
}

.typerwriter::after {
  animation-delay: 6s;
  animation-name: dropcursor;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.link-container {
  display: flex;
  color: white;
}

@keyframes blinkcursor {
  from, to { border-color: transparent }
  50% { border-color: rgb(184, 181, 181); }
}



@keyframes typing {
  from { width: 0ch }
  to { width: 6ch }
}

@keyframes erase {
  from {
      width: 6ch;
  }
  to {
      width: 0ch;
  }
}

@keyframes typing2 {
  from {
    width: 0;
  }
  to {
    width: 9ch;
  }
}

@keyframes changetext {
  from {
    content: "$ whoami";
  }
  to {
    content: "> Maxx Tandon";
  }
}

@keyframes slidetop {
  from {
    margin-top:-60%;
    height:0%;
  }
  to {
    margin-top:0%;
    height:100%;
  }
}

@keyframes slideright {
  from {
    right:-55%;
    opacity: 0%;
  }
  to {
    right: 0%;
    opacity: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.personal-logo {
  height:3rem;
  margin:auto;
  box-shadow: 0 0 20px 4px rgb(209 56 56 / 70%);
}

a {
  color: #8ee5c3;
}

.home, .portfolio {
  margin-left:15%;
  margin-top: -12px;
}

.main {
  animation-name: slidetop;
  animation-duration: 3s;
}

.btn-portfolio {
  animation-name: fadein;
  animation-delay:5s;
  animation-duration:2s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  opacity: 0;
  background-color:darkred;
}

.btn {
  transform: scale(1);
  transition:0.2s ease-out;
  color: white;
}

.btn:hover {
  transition-property: transform, background-color;
  transition-duration:0.2s;
  transform: scale(1.1);
  color: cornsilk;
  background-color: crimson;
}

.bi-arrow-right::before {
  color: black;
  width: 1em;
  display: inline-block;
  padding: 1px;
  font-size: large;
}

.main u {
  text-decoration-thickness: 1px;
  text-underline-offset: 6px;
}

.content {
  max-width: 80%;
  margin: 40px auto;
  padding: 20px;
  /* background-color:#575A5E; */
}

.extra {
  margin-left:10%;
  animation-name: slideright;
  animation-duration:3s;
  animation-delay:2.5s;
  opacity: 0%;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.me-image {
  border-radius:10rem;
}

.passion {
  width: 60%;
}

.passion-text {
  margin-left:2rem;
  text-indent: 2rem;
}

.experience-container {
  animation-name: fadein;
  animation-duration: 4s;
  animation-delay: 5.5s;
  opacity: 0%;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.experience-header {
  color: #56638A !important;
}

.experience {
  background-color:#A7A2A9;
  padding:20px;
  box-shadow: 0 0 20px 4px rgba(255, 255, 255, 0.7); /* More visible light shadow */
  border-radius: 8px; /* Optional: rounded corners */
}

.experience-header {
  position: relative;
  display: inline-block;
}

.experience-header::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; /* Thickness of the underline */
  bottom: 0;
  left: 0;
  background-color: #000000; /* Color of the underline */
  border-radius: 2px; /* Rounded corners for the underline */
}

.experience * {
  color:black;
}

.why-cs-container {
  animation-name: fadein;
  animation-duration: 5s;
  animation-delay: 6.5s;
  opacity: 0%;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.why-cs {
  background-color:#575A5E;
  padding:20px;
  box-shadow: 0 0 20px 4px #56638A; /* More visible light shadow */
  border-radius: 8px; /* Optional: rounded corners */
}

.why-cs-header {
  position: relative;
  display: inline-block;
  color: #53dd6c;
}

.why-cs-header::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; /* Thickness of the underline */
  bottom: 0;
  left: 0;
  background-color: #302f2f; /* Color of the underline */
  border-radius: 2px; /* Rounded corners for the underline */
}

.spacer {
  margin-top:20px;
  content: " ";
  display: block;
}

.contact-me {
  padding: 10px;
  margin-left:-30px;
}

.navbar {
  padding-top: 20px;
  display: flex;
  align-items: center;
  max-width: 70%;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  flex-wrap: unset;
}



.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
a:hover {
  color: #f1356d;
}

.card-title {
  color: #53dd6c;
}

.card-text {
  font-size: 15px;
}

.card-img-top {
  border: 1px solid purple;
  border-radius: 2px;
  height: 100%;
}

.card-body button {
  color: cornsilk;
}



.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.first-row {
  animation-name: fadein;
  animation-duration: 5s;
  opacity: 0;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.second-row {
  animation-name: fadein;
  animation-duration: 5s;
  animation-delay:.5s;
  opacity: 0;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@media (max-width: 768px) {
  .navbar .links {
    margin-left: 15%;
  }

  .navbar a {
    margin: auto;
  }

  .home {
    width: 100%;
  }

  .row {
    display: block;
  }

  .main {
    width: 100%;
    max-width: 100%;
  }

  .extra {
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
  }

  .experience * {
    width: 100%;
    max-width: 100%;
  }

  .col-9 {
    width: 100%;
    max-width: 100%;
  }

  .content {
    margin: 0;
  }

  .card {
    width: fit-content;
  }


  .first-row .col-md {
    display: inline;
  }

  .btn-danger {
    font-size: small;
  }

}


@media (min-width: 576px) and (max-width: 768px) { 
  .navbar .links {
    margin: auto;
  }

  .navbar {
    flex-wrap: wrap;
  }

  .content {
    margin-top: 14px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .col-md {
    margin-bottom: 2vh;
  }

  .experience-container {
    margin-top: 2vh;
  }
}

@media (max-width: 576px) {
  .navbar {
    flex-wrap: wrap;
  }
}