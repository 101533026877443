body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: radial-gradient(circle, #008000, #004d00, #001a00, #000000);
}

.Poker * {
  text-align:center;
}

.poker-nav {
  position: unset !important;
}

.home-btn {
  padding: 10px;
  border-radius: .25rem;
}

.home-btn-img {
  width: 3vw;
}

.home-btn:hover {
  background-color:#27ae60;
}

.pause-label p,
.rng-label p,
.threebet-label p,
.rfi-label p
 {
  color: white;
  margin: auto;
  border-bottom: 1px dashed;
}

.charts-btn {
  color: black;
  background-color: bisque;
}

.bi-gear {
  color: black;
}

.table-container {
  margin:auto;
  margin-top: 5%;
  position: relative;
  width: 70vw;
  /* Adjust container width to take 70% of viewport width */
  height: calc(70vw / 2);
  /* Calculate container height to maintain aspect ratio */
}

.table {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
}

.seat {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background-color: #e74c3c;
  color: white;
  text-align: center;
  line-height: 4vw;
  border-radius: 50%;
  border: 2px solid grey;
}

.card-display {
  display: flex;
  line-height: 34px;
  margin-top: 10px;
  width: calc(4.5vw + 8px);
  height: 2.5vw;
  gap: 4px;
}

.poker-card {
  position: unset;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #27ae60;
  line-height: unset;
  text-align: unset;
  font-size: unset;
  font-weight: 600;
  width: calc(2.25vw - 4px);
  padding: 0px 10px 0px 10px;
}

.folded {
  opacity: 0.5;
}

.simulation,
.sim-details,
.sim-buttons {
  position: absolute;
  color: white;
  text-align: center;
  line-height: 4vw;
}

.simulation {
  top: 45%;
  left: calc(50% - 2.25vw);
  display: none;
}

.sim-details {
  top: 35%;
  margin: auto;
  font-size: x-large;
  position: relative;
}

.sim-buttons {
  top: 60%;
  left: calc(50% - 7.5vw - .75rem);
  display: none;
}

.sim-button {
  padding: 10px;
  width: 5vw;
}

/* Adjust seat positions for 9 seats in an oval shape */
.seat:nth-child(1) {
  top: 10%;
  left: 57.5%;
  transform: translateX(-50%);
}

.seat:nth-child(2) {
  top: 20%;
  left: 74%;
  transform: translateX(-50%);
}

.seat:nth-child(3) {
  top: 46%;
  left: 86%;
  transform: translateX(-50%);
}

.seat:nth-child(4) {
  top: 75%;
  left: 70%;
  transform: translateX(-50%);
}

.seat:nth-child(5) {
  top: 83%;
  left: 50%;
  transform: translateX(-50%);
}

.seat:nth-child(6) {
  top: 75%;
  left: 30%;
  transform: translateX(-50%);
}

.seat:nth-child(7) {
  top: 46%;
  left: 14%;
  transform: translateX(-50%);
}

.seat:nth-child(8) {
  top: 20%;
  left: 26%;
  transform: translateX(-50%);
}

.seat:nth-child(9) {
  top: 10%;
  left: 42.5%;
  transform: translateX(-50%);
}

.chips.utg1 {
  transform: translateX(-50%);
}

.chips.utg2 {
  transform: translateX(-100%) translateY(-100%);
}

.chips.lj {
  transform: translateX(-25%) translateY(-200%);
}

.chips.hj {
  transform: translateY(-200%);
}

.chips.co {
  transform: translateX(25%) translateY(-200%);
}

.chips.button {
  transform: translateX(100%) translateY(-100%);
}

.chips.sb {
  transform: translateX(50%);
}

.poker-table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border: 1px solid black;
}

td {
  border: 1.5px solid black;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: aqua;
}

#popupBackground {
  display: none;
  position: absolute;
  /* overflow:scroll; */
  left: 0;
  top: 0;
  /* margin-top:1vh; */
  /* padding-top:2vw; */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#popupBackground * {
  color: black;
}

#popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position:absolute;
  top: 0;
  margin-top: 3vh;
}

#closeBtn {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

#raise-range {
  accent-color: red;
}

#call-range {
  accent-color: green;
}

#fold-range {
  accent-color: aqua;
}

.slide-container,
.slide-container-result {
  display: inline-flex;
}

.slide-result {
  padding: 6px;
}

.slider {
  padding: 5px;
}

.sim-results {
  color: white;
  font-size: large;
  gap: 10px;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .poker-nav {
    display: inline-block;
  }
}

@media (max-width: 576px) {
  .sim-details {
    font-size: unset;
    top: 90%;
  }
}

@media (max-width: 576px) {
  .home-btn-img {
    width: 15vw;
  }

  #popupBackground {
    z-index:2;
  }

  .table-container {
    width: 40vh;
    height: 70vh; /* Increase container height to maintain vertical oval aspect ratio */
  }

  .card-display {
    height: unset;
  }

  .poker-card {
    width: 8vw;
    height: 14vw;
  }

  .simulation {
    left: calc(50% - 7vw);
  }

  .btn-run-simulation {
    margin-top: 2vw;
    margin-bottom: 5vw;
  }

  .sim-details {
    z-index: 1;
  }

  .sim-button {
    width: 14vw;
  }

  .sim-buttons {
    left: calc(50% - 21vw - .75rem);
    top: 95%;
  }

  .chips {
    font-size: smaller;
  }

  .seat {
    width: 10vw;
    height: 10vw;
    font-size: small;
    line-height: 10vw;
  }

  /* Adjust seat positions for 9 seats in a vertical oval shape */
  .seat:nth-child(1) {
    top: 15%;
    left: 65%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(2) {
    top: 30%;
    left: 78%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(3) {
    top: 45%;
    left: 85%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(4) {
    top: 60%;
    left: 78%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(5) {
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(6) {
    top: 60%;
    left: 22%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(7) {
    top: 45%;
    left: 15%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(8) {
    top: 30%;
    left: 22%;
    transform: translate(-50%, -50%);
  }

  .seat:nth-child(9) {
    top: 15%;
    left: 35%;
    transform: translate(-50%, -50%);
  }
}
